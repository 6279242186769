import { red } from '@mui/material/colors';
import React, { useEffect, useState } from 'react';

// Creating a RedisContext
const RedisContext = React.createContext();

/**
 * RedisProvider component to manage and provide Redis home state to the application.
 * 
 * This component handles Home page datas from the redis server.
 * It provides functions to update the homepage block details.
 * 
 * @param {React.ReactNode} children - The child components of ReactProvider.
 */
const RedisProvider = ({ children ,redisPageData}) => {
 
  console.log("RedisPage Data",redisPageData);
  // State management for Redis home page datas
  const [calendlyScriptData,setCalendlyScriptData]=useState();
  const [menuBarData,setMenuBarData]=useState();
  const [redisProductDetailPage, setRedisProductDetailPage] = useState([]); 
  const [redisProductPage, setRedisProductPage] = useState([]); 
  const [seoSettingData,setSeoSettingData]=useState();
  const [shareThisData,setShareThisData]=useState();
  const [titleBarData,setTitleBarData]=useState();
  const [redisWidgetOrderData, setRedisWidgetOrderData] = useState([]); 
  const [footerDatas,setFooterDatas]=useState();

useEffect(() => {
  if(!redisPageData)return
  setCalendlyScriptData(redisPageData.calendly_script);
  setMenuBarData(redisPageData.menu_bar);
  setRedisProductDetailPage(redisPageData.product_detail_page);
  setRedisProductPage(redisPageData.products_page);
  setSeoSettingData(redisPageData.seo_setting);
  setShareThisData(redisPageData.share_this);
  setTitleBarData(redisPageData.title_bar);
setRedisWidgetOrderData(redisPageData.widget_order);
setFooterDatas(redisPageData.footer);
},[redisPageData])


  // Providing the Redis context to child components
  return (
    <RedisContext.Provider value={{ footerDatas,calendlyScriptData,menuBarData,seoSettingData,shareThisData,titleBarData,redisWidgetOrderData,redisProductDetailPage,redisProductPage }}>
      {children}
    </RedisContext.Provider>
  );
};

export { RedisContext, RedisProvider };
